import React, { useState } from 'react';
import { Table, Pagination } from 'antd';

const CustomTable = ({ columns, data, pageSizeOptions = ['10', '20', '50'], defaultPageSize = 10 }) => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handlePageChange = (page, pageSize) => {
    setCurrent(page);
    setPageSize(pageSize);
  };

  const paginatedData = data.slice((current - 1) * pageSize, current * pageSize);

  return (
    <>
      <Table
        columns={columns}
        dataSource={paginatedData}
        pagination={false}
        rowKey={(record) => record.key}
      />
      <Pagination
        current={current}
        pageSize={pageSize}
        total={data.length}
        // showSizeChanger
        onChange={handlePageChange}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};

export default CustomTable;
