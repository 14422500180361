import React from "react";

const CustomCard = ({ title, children }) => {
  return (
    <>
      <main className="bg-white p-4 rounded rounded-lg">
        <h4 className="font-bold text-primary">{title}</h4>
        {children}
      </main>
    </>
  );
};

export default CustomCard;
