import { Avatar, Space } from "antd";
import React from "react";
import ProfilePicture from "../../assets/images/avatar2.jpg";
import { MdCameraAlt, MdLogout } from "react-icons/md";
import CustomButton from "../../components/custom-button";

const Profile = () => {
  return (
    <>
      <main>
        <section className="p-5 gap-3 flex items-center">
          <Space wrap className="relative">
            <Avatar size={100} src={ProfilePicture} />
            <div className="absolute bottom-5 right-[-3px]">
              <div className="bg-bgLight cursor-pointer w-8 rounded-full flex items-center justify-center h-8">
                <MdCameraAlt className="text-xl" />
              </div>
            </div>
          </Space>
          <div>
            <h1 className="text-lg font-semibold">Michael Roberts</h1>
            <p className="text-secondaryText">Administrator</p>
            <p>info@gmail.com</p>
          </div>
        </section>
        <CustomButton className="bg-primary hover:bg-secondary flex items-center gap-1 text-white">
          Logout
          <MdLogout />
        </CustomButton>
      </main>
    </>
  );
};

export default Profile;
