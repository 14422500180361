import  AppLayout  from "../layouts/app-layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Employees } from "../page/hr";
import { NewEmployee } from "../sections";
import Home from "../page/home";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/human-resources" element={<AppLayout />}>
          <Route path="employees" element={<Employees />} />
          <Route path="new-employees" element={<NewEmployee />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
