import React, { useState } from "react";
import { Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const CustomAccordion = ({ panels }) => {
  const [activeKey, setActiveKey] = useState(["0"]);

  const onChange = (key) => {
    setActiveKey(key);
  };

  const getHeader = (header, isActive) => (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <span className="font-semibold text-sm text-primary">{header}</span>
      {isActive ? <MinusOutlined /> : <PlusOutlined />}
    </div>
  );

  return (
    <Collapse activeKey={activeKey} onChange={onChange} defaultActiveKey={["0"]}>
      {panels.map((panel, index) => (
        <Panel header={getHeader(panel.header, activeKey.includes(index.toString()))} key={index.toString()}>
          {panel.content}
        </Panel>
      ))}
    </Collapse>
  );
};

export default CustomAccordion;
