import React, { useState } from "react";
import { CustomButton, CustomInput, CustomSelect } from "../../../../components";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";

const Education = () => {
  const [educationList, setEducationList] = useState([
    { institutionName: "", degree: "", fieldOfStudy: "", graduationYear: "" },
  ]);

  const dummyOptions = [
    { value: "", label: "Select" },
    { value: "option 1", label: "Option 1" },
    { value: "option 2", label: "Option 2" },
  ];

  const addEducation = () => {
    setEducationList([...educationList, { institutionName: "", degree: "", fieldOfStudy: "", graduationYear: "" }]);
  };

  const deleteEducation = (index) => {
    const updatedEducationList = educationList.filter((_, i) => i !== index);
    setEducationList(updatedEducationList);
  };

  const handleInputChange = (index, field, value) => {
    const updatedEducationList = [...educationList];
    updatedEducationList[index][field] = value;
    setEducationList(updatedEducationList);
  };

  return (
    <form>
      {educationList.map((education, index) => (
        <div key={index} className="mb-10">
        <div className="flex justify-between items-center mb-3">
            <h2 className="text-xl font-semibold">Education {index + 1}</h2>
            {educationList.length > 1 && <FaRegTrashAlt className="mr-2 text-red-500 text-xl cursor-pointer" onClick={() => deleteEducation(index)} />}
          </div>
          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-12">
              <CustomInput
                label="Institution Name"
                value={education.institutionName}
                onChange={(e) => handleInputChange(index, "institutionName", e.target.value)}
              />
            </div>
          </section>

          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-4">
              <CustomSelect
                label="Degree"
                name="selectOption"
                options={dummyOptions}
                value={education.degree}
                onChange={(e) => handleInputChange(index, "degree", e.target.value)}
              />
            </div>
            <div className="col-span-4">
              <CustomInput
                label="Field of Study"
                value={education.fieldOfStudy}
                onChange={(e) => handleInputChange(index, "fieldOfStudy", e.target.value)}
              />
            </div>
            <div className="col-span-4">
              <CustomInput
                type="date"
                label="Graduation Year"
                value={education.graduationYear}
                onChange={(e) => handleInputChange(index, "graduationYear", e.target.value)}
              />
            </div>
          </section>
          <div className="flex justify-center">
            <hr className="pt-5 w-6/12 text-primary" />
          </div>
        </div>
      ))}

      <CustomButton
        type="button"
        className="flex items-center font-semibold text-white bg-primary hover:bg-secondary justify-center mb-8 mt-4"
        onClick={addEducation}
      >
        <span className="pr-2">
          <FaPlus />
        </span>
        <p>Add Another Education</p>
      </CustomButton>
    </form>
  );
};

export default Education;
