import React from "react";
import { Card } from ".";

const CustomDashlets = ({ number, title, icon }) => {
  return (
    <>
      <Card>
        <main className="flex justify-between items-center">
          <div>
            <p className="font-semibold text-sm">{title}</p>
            <h5 className="text-xl font-bold">{number}</h5>
          </div>
          <div className="bg-primary text-bgLight p-2 text-2xl rounded-full flex items-center justify-center w-12 h-12">
            {icon}
          </div>
        </main>
      </Card>
    </>
  );
};

export default CustomDashlets;
