import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;

const CustomSearch = ({ placeholder, onSearch, debounceTimeout }) => {
  const [searchTerm, setSearchTerm] = useState("");
  let debounceTimeoutId;

  const handleSearch = (value) => {
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }
    debounceTimeoutId = setTimeout(() => {
      onSearch(value);
    }, debounceTimeout);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  return (
    <Search
      className="custom-search-input"
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleChange}
      enterButton={
        <div className="flex item-center justify-center">
          <SearchOutlined />
        </div>
      }
      size="large"
    />
  );
};

// Search Usage
// const [results, setResults] = useState([]);

// const handleSearch = (query) => {
  //   console.log("Searching for:", query);
  //   setResults([`Result for ${query}`]);
  // };
{
  /* <Search placeholder="Find Users" onSearch={handleSearch} /> */
}
{
  /* <div className="results">
              {results.map((result, index) => (
                <p className="text-sm text-primary" key={index}>
                  {result}
                </p>
              ))}
            </div> */
}

CustomSearch.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
};

CustomSearch.defaultProps = {
  placeholder: "Search...",
  debounceTimeout: 300,
};

export default CustomSearch;
