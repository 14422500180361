import React, { useState } from "react";
import { CustomButton, CustomInput } from "../../../../components";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";

const WorkExperience = () => {
  const [experienceList, setExperienceList] = useState([
    { employer: "", position: "", reasonForLeaving: "", endDate: "", startDate: "" },
  ]);

  const addExperience = () => {
    setExperienceList([
      ...experienceList,
      { employer: "", position: "", reasonForLeaving: "", endDate: "", startDate: "" },
    ]);
  };

  const deleteExperience = (index) => {
    const updatedExperienceList = experienceList.filter((_, i) => i !== index);
    setExperienceList(updatedExperienceList);
  };

  const handleInputChange = (index, field, value) => {
    const updatedExperienceList = [...experienceList];
    updatedExperienceList[index][field] = value;
    setExperienceList(updatedExperienceList);
  };

  return (
    <form>
      {experienceList.map((experience, index) => (
        <div key={index} className="mb-10">
          <div className="flex justify-between items-center mb-3">
            <h2 className="text-xl font-semibold">Work Experience {index + 1}</h2>
            {experienceList.length > 1 && (
              <FaRegTrashAlt
                className="mr-2 text-red-500 text-xl cursor-pointer"
                onClick={() => deleteExperience(index)}
              />
            )}
          </div>
          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-6">
              <CustomInput
                label="Employer"
                value={experience.employer}
                onChange={(e) => handleInputChange(index, "employer", e.target.value)}
              />
            </div>
            <div className="col-span-6">
              <CustomInput
                label="Position"
                value={experience.position}
                onChange={(e) => handleInputChange(index, "position", e.target.value)}
              />
            </div>
          </section>

          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-6">
              <CustomInput
                type="date"
                label="Start Date"
                value={experience.startDate}
                onChange={(e) => handleInputChange(index, "startDate", e.target.value)}
              />
            </div>
            <div className="col-span-6">
              <CustomInput
                type="date"
                label="End Date"
                value={experience.endDate}
                onChange={(e) => handleInputChange(index, "endDate", e.target.value)}
              />
            </div>
          </section>
          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-12">
              <CustomInput
                label="Reason for Leaving"
                value={experience.reasonForLeaving}
                onChange={(e) => handleInputChange(index, "reasonForLeaving", e.target.value)}
              />
            </div>
          </section>
          <div className="flex justify-center">
            <hr className="pt-5 w-6/12 text-primary" />
          </div>
        </div>
      ))}

      <CustomButton
        type="button"
        className="flex items-center font-semibold text-white bg-primary hover:bg-secondary justify-center mb-8 mt-4"
        onClick={addExperience}
      >
        <span className="pr-2">
          <FaPlus />
        </span>
        <p>Add Another experience</p>
      </CustomButton>
    </form>
  );
};

export default WorkExperience;
