import React from "react";
import { links } from "../../constant/sidebar-data";
import { NavLink } from "react-router-dom";
import SideBottom from "../../assets/images/people.svg";

const sidebar = () => {
  const activeLink = "flex items-center bg-newBg gap-2 pl-4 py-3 rounded-lg text-bgLight text-md m-2";
  const normalLink =
    "flex items-center gap-2 pl-4 py-3 rounded-lg text-gray-700 text-md hover:bg-gray-100 mx-2 my-3 hover:text-bgLight";

  return (
    <>
      <div className="p-5 mb-5 text-primary">
        <h1 className="font-bold text-4xl">Artinict</h1>
      </div>

      {links.map((item) => (
        <div key={item.title}>
          {/* <p className="text-primary text-sm font-semibold m-3 mt-4 uppercase">{item.title}</p> */}
          {item.links.map((link) => (
            <NavLink
              to={`/human-resources/${link.path}`}
              key={link.name}
              exact
              style={({ isActive }) => ({
                backgroundColor: isActive ? "#4b49ac" : "",
                color: isActive ? "white" : "gray",
              })}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              {link.icon}
              <span className="capitalize font-semibold">{link.name}</span>
            </NavLink>
          ))}
        </div>
      ))}
      {/* <div className="sidebar-svg">
        <img src={SideBottom} alt="image" />
      </div> */}
    </>
  );
};

export default sidebar;
