import React from "react";
import { CustomInput, CustomSelect } from "../../../../components";

const PersonalInformation = () => {
  const gender = [
    { value: "", label: "Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const dummy = [
    { value: "", label: "Select" },
    { value: "option 1", label: "Option 1" },
    { value: "option 2", label: "Option 2" },
  ];
  return (
    <form>
      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-6">
          <CustomInput label="Employee ID" />
        </div>
        <div className="col-span-6">
          <CustomInput label="NIN" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-4">
          <CustomInput label="First Name" />
        </div>
        <div className="col-span-4">
          <CustomInput label="Middle Name" />
        </div>
        <div className="col-span-4">
          <CustomInput label="Last Name" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-4">
          <CustomSelect label="Gender" name="selectOption" options={gender} />
        </div>
        <div className="col-span-4">
          <CustomInput type="date" label="DOB" />
        </div>
        <div className="col-span-4">
          <CustomInput label="Marital Status" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-4">
          <CustomInput label="Email" />
        </div>
        <div className="col-span-4">
          <CustomInput label="Phone Number" />
        </div>
        <div className="col-span-4">
          <CustomInput label="Alternative Phone Number" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-4">
          <CustomInput label="Address" />
        </div>
        <div className="col-span-4">
          <CustomSelect label="City" name="selectOption" options={dummy} />
        </div>
        <div className="col-span-4">
          <CustomSelect label="State" name="selectOption" options={dummy} />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-4">
          <CustomInput label="Postal Code" />
        </div>
        <div className="col-span-4">
          <CustomSelect label="Country" name="selectOption" options={dummy} />
        </div>
        <div className="col-span-4">
          <CustomSelect label="Nationality" name="selectOption" options={dummy} />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-6">
          <CustomSelect label="Languages" name="selectOption" options={dummy} />
        </div>
      </section>
    </form>
  );
};

export default PersonalInformation;
