import {
  MdOutlineSettings,
  MdOutlinePeopleAlt,
  MdOutlineDashboard,
  MdOutlineAccountBalanceWallet,
} from "react-icons/md";
import { BiBuildings } from "react-icons/bi";
import { TbReportAnalytics, TbBriefcase } from "react-icons/tb";
import { GrShieldSecurity, GrChatOption, GrHostMaintenance } from "react-icons/gr";

export const links = [
  // {
  //   title: "Dashboard",
  //   links: [
  //     {
  //       path: "/",
  //       name: "Admin Dashboard",
  //       icon: <MdOutlineDashboard />,
  //     },
  //     {
  //       path: "user-management",
  //       name: "User Management",
  //       icon: <MdOutlinePeopleAlt />,
  //     },
  //     {
  //       path: "maintenance-management",
  //       name: "Maintenance",
  //       icon: <GrHostMaintenance />,
  //     },
  //     {
  //       path: "facility-management",
  //       name: "Facility Management",
  //       icon: <TbBriefcase />,
  //     },
  //     {
  //       path: "property-management",
  //       name: "Property Management",
  //       icon: <BiBuildings />,
  //     },
  //     {
  //       path: "security-management",
  //       name: "Security Management",
  //       icon: <GrShieldSecurity />,
  //     },
  //     {
  //       path: "financial-summaries",
  //       name: "Financial Summaries",
  //       icon: <MdOutlineAccountBalanceWallet />,
  //     },
  //     {
  //       path: "reports",
  //       name: "Reports",
  //       icon: <TbReportAnalytics />,
  //     },
  //     {
  //       path: "chats",
  //       name: "Chats",
  //       icon: <GrChatOption />,
  //     },
  //     {
  //       path: "settings",
  //       name: "Settings",
  //       icon: <MdOutlineSettings />,
  //     },

  //   ],
  // },
  {
    title: "HR Dashboard",
    links: [
      {
        path: "/",
        name: "Dashboard",
        icon: <MdOutlineDashboard />,
      },
      {
        path: "employees",
        name: "Employees",
        icon: <MdOutlinePeopleAlt />,
      },
      // {
      //   path: "maintenance-management",
      //   name: "Maintenance",
      //   icon: <GrHostMaintenance />,
      // },
      // {
      //   path: "facility-management",
      //   name: "Facility Management",
      //   icon: <TbBriefcase />,
      // },
      // {
      //   path: "property-management",
      //   name: "Property Management",
      //   icon: <BiBuildings />,
      // },
      // {
      //   path: "security-management",
      //   name: "Security Management",
      //   icon: <GrShieldSecurity />,
      // },
      // {
      //   path: "financial-summaries",
      //   name: "Financial Summaries",
      //   icon: <MdOutlineAccountBalanceWallet />,
      // },
      // {
      //   path: "reports",
      //   name: "Reports",
      //   icon: <TbReportAnalytics />,
      // },
      // {
      //   path: "chats",
      //   name: "Chats",
      //   icon: <GrChatOption />,
      // },
      // {
      //   path: "settings",
      //   name: "Settings",
      //   icon: <MdOutlineSettings />,
      // },
    ],
  },
  // {
  //   title: "HR Apps",
  //   links: [
  //     {
  //       path: "all_employees",
  //       name: "All Employees",
  //       icon: <FaPeopleGroup />,
  //     },
  //     {
  //       path: "employee_payroll",
  //       name: "Employees Payroll",
  //       icon: <MdOutlinePayments />,
  //     },
  //     {
  //       path: "attendance",
  //       name: "Attendance",
  //       icon: <HiOutlinePresentationChartBar />,
  //     },
  //     {
  //       path: "leave_requests",
  //       name: "Leave Requests",
  //       icon: <TbLayoutSidebarRightCollapse />,
  //     },
  //     {
  //       path: "schedules",
  //       name: "Schedules",
  //       icon: <GrSchedules />,
  //     },
  //   ],
  // },
  // {
  //   title: "Apps",
  //   links: [
  //     {
  //       path: "accounting",
  //       name: "Accounting",
  //       icon: <TbCurrencyNaira />,
  //     },
  //     {
  //       path: "property-listings",
  //       name: "Property Listings",
  //       icon: <BsBuildingFill />,
  //     },
  //     {
  //       path: "announcement",
  //       name: "Announcement",
  //       icon: <MdPayment />,
  //     },
  //     {
  //       path: "help_desk",
  //       name: "Help Desk",
  //       icon: <MdPayment />,
  //     },
  //   ],
  // },
  // {
  //   title: "Apps",
  //   links: [
  //     {
  //       path: "apply_leave",
  //       name: "Leave",
  //       icon: <TbCurrencyNaira />,
  //     },
  //     {
  //       path: "attendance",
  //       name: "Attendance",
  //       icon: <BsBuildingFill />,
  //     },
  //     {
  //       path: "payment_history",
  //       name: "Payment History",
  //       icon: <MdPayment />,
  //     },
  //   ],
  // },
];
