import React from "react";

const Header = ({ category, title }) => {
  return (
    <>
    <div className="p-2">
      <p className="text-gray-400">{category}</p>
      <p className="text-2xl font-bold tracking-tight text-black">
        {title}
      </p>
    </div>
    </>
    
  );
};

export default Header;
 