import { Card, CustomButton, CustomTable, Filter, Modal } from "../../../components";
import { filterUser, userColumns } from "../../../constant/admin-constant";
import { userDataSource } from "../../../data/dummyData";
import { FaPlus } from "react-icons/fa6";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover, Tooltip } from "antd";
import { MdMoreVert } from "react-icons/md";
import { employeeFilter } from "../../../constant/employee-constant";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import { TbLogs } from "react-icons/tb";

const EmployeeTable = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(userDataSource);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const handleFilter = (filters) => {
    const filtered = userDataSource.filter((item) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key]) return true;
        if (key === "age") return item[key] === filters[key];
        return item[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    setFilteredData(filtered);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addNewEmployee = () => {
    navigate("/human-resources/new-employees");
  };

  const content = (
    <div className="flex items-center justify-center">
      <Tooltip placement="bottom" overlayInnerStyle={{ fontSize: "12px" }} title="Delete Employee">
        <FaRegTrashAlt className="text-2xl text-red-500 mx-1 cursor-pointer" />
      </Tooltip>
      <Tooltip placement="bottom" overlayInnerStyle={{ fontSize: "12px" }} title="Edit Employee">
        <MdOutlineEdit className="text-2xl mx-1 cursor-pointer" />
      </Tooltip>
      <Tooltip placement="bottom" overlayInnerStyle={{ fontSize: "12px" }} title="View Employee Logs">
        <TbLogs className="text-2xl mx-1 cursor-pointer" />
      </Tooltip>
    </div>
  );

  const columns = userColumns({
    onMore: (record) => () =>
      (
        <Popover content={content} placement="bottom" trigger="click">
          <MdMoreVert className="cursor-pointer text-center text-lg hover:text-secondary" />
        </Popover>
      ),
  });

  return (
    <>
      <main>
        <section className="flex justify-end item-center">
          <div className="flex gap-4">
            <Filter
              filterTitle="Filter Employees By"
              filterContent={employeeFilter.map(({ text }) => (
                <p className="my-2 cursor-pointer">{text}</p>
              ))}
              filterText="Filter Employees By"
              fields={filterUser}
              onFilter={handleFilter}
            />

            <CustomButton
              onClick={addNewEmployee}
              className="flex items-center font-semibold text-white bg-primary hover:bg-secondary justify-center"
            >
              <span className="pr-2">
                <FaPlus />
              </span>
              <p>Add New Employee</p>
            </CustomButton>
          </div>
        </section>

        <main className="mt-5">
          <Card title="All Staffs">
            <CustomTable columns={columns} data={filteredData} />
          </Card>
        </main>
      </main>

      <Modal width="600px" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} title={modalTitle}>
        {modalContent}
      </Modal>
    </>
  );
};

export default EmployeeTable;
