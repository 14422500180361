// import React from "react";
// import { Form, Input } from "antd";

// const CustomInput = ({ label, name, value, onChange, onBlur, error, disabled, type, touched, style = {}, className = "" }) => (
//   <Form.Item
//     label={label}
//     validateStatus={touched && error ? "error" : ""}
//     help={touched && error ? error : ""}
//     className={className}
//     labelCol={{ style: { marginBottom: "-7px", ...style } }}
//   >
//     <Input name={name} disabled={disabled} value={value} type={type} onChange={onChange} onBlur={onBlur} className="mt-1" />
//   </Form.Item>
// );

// export default CustomInput;
import React from "react";
import { Form, Input } from "antd";

const CustomInput = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  type,
  touched,
  style = {},
  className = ""
}) => (
  <Form.Item
    // label={label}
    validateStatus={touched && error ? "error" : ""}
    help={touched && error ? error : ""}
    className={className}
    style={{ marginBottom: "2rem", ...style }}
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
  >
    {label}
    <Input
      name={name}
      disabled={disabled}
      value={value}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      // style={{ marginTop: "-5rem" }}
    />
  </Form.Item>
);

export default CustomInput;