// // File: src/components/Wizard.js

// import React, { useState } from "react";
// import CustomButton from "./custom-button";

// const Wizard = ({ steps }) => {
//   const [currentStep, setCurrentStep] = useState(0);

//   const handleStepClick = (index) => {
//     setCurrentStep(index);
//   };
//   const nextStep = () => {
//     setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
//   };

//   const prevStep = () => {
//     setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
//   };

//   return (
//     <div className="wizard-container">
//       <div className="wizard-sidebar ">
//         {steps.map((step, index) => (
//           <button
//             key={index}
//             className={`wizard-step my-2 ${index === currentStep ? "active" : ""}`}
//             onClick={() => handleStepClick(index)}
//           >
//             {step.title}
//           </button>
//         ))}
//       </div>
//       <div className="wizard-content">
//         <h2 className="font-semibold text-lg mb-5">{steps[currentStep].title}</h2>
//         <div>{steps[currentStep].content}</div>
//         <div className="flex gap-5 my-10">
//           <CustomButton className="bg-gray-300 font-semibold cursor-pointer" onClick={prevStep} disabled={currentStep === 0}>
//             Previous
//           </CustomButton>
//           <CustomButton
//             className="bg-primary text-white font-semibold cursor-pointer"
//             onClick={nextStep}
//             disabled={currentStep === steps.length - 1}
//           >
//             Next
//           </CustomButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Wizard;

import React, { useState } from "react";
import CustomButton from "./custom-button";

const Wizard = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepClick = (index) => {
    setCurrentStep(index);
  };
  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <main className="grid grid-cols-12 gap-5">
      <section className="col-span-3">
        {steps.map((step, index) => (
          <button
            key={index}
            className={`wizard-step my-2 ${index === currentStep ? "active" : ""}`}
            onClick={() => handleStepClick(index)}
          >
            {step.title}
          </button>
        ))}
      </section>
      <section className="col-span-9 mb-40 p-5 border border-md rounded rounded-lg ">
        <h2 className="font-semibold text-lg mb-5">{steps[currentStep].title}</h2>
        <div>{steps[currentStep].content}</div>
        <div className="flex gap-5 my-2">
          <CustomButton
            className="bg-gray-300 font-semibold cursor-pointer"
            onClick={prevStep}
            disabled={currentStep === 0}
          >
            Previous
          </CustomButton>
          <CustomButton
            className="bg-primary hover:bg-secondary text-white font-semibold cursor-pointer"
            onClick={nextStep}
            disabled={currentStep === steps.length - 1}
          >
            Next
          </CustomButton>
        </div>
      </section>
    </main>
  );
};

export default Wizard;
