import React from "react";
import { CustomWizard, Header } from "../../../components";
import {
  BenefitInfoForm,
  Certification,
  EducationForm,
  EmergencyContactForm,
  HealthInsuranceForm,
  PensionInfoForm,
  PersonalInformationForm,
  WorkExperienceForm,
} from "../..";

function NewEmployee() {
  const steps = [
    { title: "Personal Infomation", content: <PersonalInformationForm /> },
    { title: "Emergency Contact", content: <EmergencyContactForm /> },
    { title: "Educational Information", content: <EducationForm /> },
    { title: "Health Insurance", content: <HealthInsuranceForm /> },
    { title: "Benefit Information", content: <BenefitInfoForm /> },
    { title: "Work Experience", content: <WorkExperienceForm /> },
    { title: "Pension Info", content: <PensionInfoForm /> },
    { title: "Certification", content: <Certification/> },
  ];

  return (
    <div>
      <Header title="Add New Employee" />
      <section className="mt-4">
        <CustomWizard steps={steps} />
      </section>
    </div>
  );
}

export default NewEmployee;
