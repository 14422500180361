import { Header } from "../../components";
import { EmployeeDashlet, EmployeeTable } from "../../sections";

const Employees = () => {
  return (
    <>
      <main>
        <Header title="Employees" />
        <section className="p-2">
          <EmployeeDashlet />
          <main className="py-5">
            <EmployeeTable />
          </main>
        </section>
      </main>
    </>
  );
};

export default Employees;
