import { CustomButton } from "../components";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const proceed = () => {
    navigate("/human-resources/employees");
  };
  return (
    <div className="flex flex-col items-center h-screen justify-center">
      <p className="py-5 text-2xl font-semibold">Welcome!</p>
      <CustomButton onClick={proceed} className="bg-primary text-white">
        Proceed
      </CustomButton>
    </div>
  );
};

export default Home;
