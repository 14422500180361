import React, { useState } from "react";
import { MdOutlineNotifications, MdOutlineSearch, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Avatar, Popover, Space } from "antd";
import ProfilePicture from "../../assets/images/avatar2.jpg";
import DashboardProfile from "./profile";
import Notifications from "./notification";

  const DashboardHeader = () => {
  const [visible, setVisible] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  return (
    <>
      <main className="p-2 flex items-center justify-between">
        <div>
          <p className="text-black text-xl font-semibold">Hi Jane, Smith</p>
          <p className="text-black text-sm">Login Time: 23:09 PM</p>
        </div>

        <section className="flex items-center">
          <div className="bg-white text-2xl text-primary cursor-pointer hover:bg-secondary hover:text-bgLight rounded rounded-lg p-3 mx-2">
            <MdOutlineSearch />
          </div>
          <div className="bg-white text-2xl text-primary cursor-pointer hover:bg-secondary hover:text-bgLight rounded rounded-lg p-3 mx-2">
            <Popover
              content={<Notifications />}
              className="notification text-primary"
              title="Notifications"
              trigger="click"
              visible={showNotification}
              onVisibleChange={setShowNotification}
            >
              <MdOutlineNotifications className="text-2xl cursor-pointer" />
            </Popover>
          </div>
          <Space wrap>
            <Avatar size={50} className="bg-primary border-4 border-white rounded-full" src={ProfilePicture} />
            <Popover
              content={<DashboardProfile />}
              className="profile text-primary"
              title="My Profile"
              trigger="click"
              visible={visible}
              onVisibleChange={setVisible}
            >
              <MdOutlineKeyboardArrowDown className="text-2xl cursor-pointer" />
            </Popover>
          </Space>
        </section>
      </main>
    </>
  );
};

export default DashboardHeader;
