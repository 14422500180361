import React from "react";
import { Form, Select } from "antd";

const { Option } = Select;

const CustomSelect = ({ label, name, value, onChange, placeholder, onBlur, error, touched, options, className = "" }) => (
  <Form.Item
    // label={label}
    validateStatus={touched && error ? "error" : ""}
    help={touched && error ? error : ""}
    className={className}
  >
    {label}
    <Select
      name={name}
      value={value}
      onChange={(value) => onChange({ target: { name, value } })}
      onBlur={onBlur}
      placeholder={placeholder}
      // className="mt-1 w-full"
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

export default CustomSelect;
