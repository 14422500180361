
import React from 'react';

const CustomButton = ({ type = "button", onClick, children, className = "", ...props }) => (
  <button
    type={type}
    onClick={onClick}
    className={`px-4 py-3 rounded rounded-2 ${className}`} 
    {...props} 
  >
    {children}
  </button>
);

export default CustomButton;
