import React, { useState } from "react";
import { Form, Input, Select, DatePicker, Popover } from "antd";
import CustomButton from "./custom-button";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { MdKeyboardArrowDown } from "react-icons/md";

const { Option } = Select;

const CustomFilter = ({ fields, filterText, filterTitle, filterContent, onFilter }) => {
  const [form] = Form.useForm();
  const [setFilters] = useState({});
  //   const [filters, setFilters] = useState({});
  const [visible, setVisible] = useState(false);

  const handleFinish = (values) => {
    setFilters(values);
    onFilter(values);
    setVisible(false);
  };

  const content = <div className="filter-popover-width">{filterContent}</div>;

  return (
    <Popover
      content={content}
      className="text-primary"
      placement="bottom"
      title={filterTitle}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      style={{ width: '500px' }}
    >
      <div className="text-primary cursor-pointer flex gap-2 items-center font-semibold">
        <span>
          <TbAdjustmentsHorizontal />
        </span>
        <p className="flex items-center">
          {filterText}
          <span>
            <MdKeyboardArrowDown className="text-2xl font-bold" />
          </span>
        </p>
      </div>
    </Popover>
  );
};

export default CustomFilter;
