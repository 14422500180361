import { Modal } from "antd";
import CustomButton from "./custom-button";

const CustomModal = ({ visible, onOk, onCancel, title, children, width = "500px" }) => {
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      width={width}
      footer={[
        <div className="flex justify-end gap-2">
          <CustomButton className="bg-primary hover:bg-secondary text-white" children="Submit" onClick={onOk} />
          <CustomButton className="bg-primary hover:bg-secondary text-white" children="Back" onClick={onCancel} />
        </div>,
      ]}
    >
      {children}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}></div>
    </Modal>
  );
};

export default CustomModal;
