import { Dashlet } from "../../../components";
import { BiBuildingHouse, BiUserCheck, BiUserX } from "react-icons/bi";
import { FaPeopleGroup } from "react-icons/fa6";
import EmployeeImg from "../../../assets/images/people.svg";

const UserDashlet = () => {
  const dashInfo = [
    { no: "40", ic: <FaPeopleGroup />, title: "All Employees" },
    { no: "128", ic: <BiBuildingHouse />, title: "Active" },
    { no: "16", ic: <BiUserCheck />, title: "Terminated" },
    { no: "21", ic: <BiUserX />, title: "Resigned" },
    { no: "40", ic: <FaPeopleGroup />, title: "Full-time" },
    { no: "128", ic: <BiBuildingHouse />, title: "Part-Time" },
    { no: "16", ic: <BiUserCheck />, title: "Adhoc" },
    { no: "21", ic: <BiUserX />, title: "Remote " },
    // { no: "25", ic: <BsHouseX />, title: "Unoccupied Units" },
  ];
  return (
    <>
      <main className="grid grid-cols-12 items-center gap-5">
        <section className="col-span-4">
          <img src={EmployeeImg} alt="employee_img" className="rounded rounded-2xl" />
        </section>
        <section className="col-span-8 ">
          <div className="grid text-black grid-cols-4 gap-5">
            {dashInfo.map(({ ic, no, title }) => (
              <div className="col-span-1">
                <Dashlet title={title} icon={ic} number={no} />
              </div>
            ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default UserDashlet;
