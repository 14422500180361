export const userDataSource = [
  {
    key: "1",
    employee_id: 1002,  
    first_name: "Mike ",
    last_name: "Jennings",
    email: "mike@gmail.com",
    phone_number: "07085XXXXXX",
    department: "Dentist",
    designation: "Facility Officer",
    status: "available",
    actions: "",
  },
  {
    key: "2",
    employee_id: 1003,
    first_name: "John ",
    last_name: "Smith",
    email: "john@gmail.com",
    phone_number: "07033XXXXXX",
    department: "Dentist",
    designation: "Maintenance Officer",
    status: "unavailable",
    actions: "",
  },
  {
    key: "3",
    employee_id: 1004,
    first_name: "Vincent ",
    last_name: "VanGogh",
    email: "vin@gmail.com",
    phone_number: "07087XXXXXX",
    department: "Dentist",
    designation: "Security Guard",
    status: "available",
    actions: "",
  },
  {
    key: "4",
    employee_id: 1004,
    first_name: "Vincent ",
    last_name: "VanGogh",
    email: "vin@gmail.com",
    phone_number: "07087XXXXXX",
    department: "Dentist",
    designation: "Security Guard",
    status: "available",
    actions: "",
  },
  {
    key: "5",
    employee_id: 1004,
    first_name: "Vincent ",
    last_name: "VanGogh",
    email: "vin@gmail.com",
    phone_number: "07087XXXXXX",
    department: "Dentist",
    designation: "Security Guard",
    status: "available",
    actions: "",
  },
  {
    key: "6",
    employee_id: 1004,
    first_name: "Vincent ",
    last_name: "VanGogh",
    email: "vin@gmail.com",
    phone_number: "07087XXXXXX",
    department: "Dentist",
    designation: "Security Guard",
    status: "available",
    actions: "",
  },
  {
    key: "7",
    employee_id: 1004,
    first_name: "Vincent ",
    last_name: "VanGogh",
    email: "vin@gmail.com",
    phone_number: "07087XXXXXX",
    department: "Dentist",
    designation: "Security Guard",
    status: "available",
    actions: "",
  },
];

export const roleData = [
  { label: "Security Guards", value: "security" },
  { label: "Maintenance Officer", value: "maintenance_officer" },
];
