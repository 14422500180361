import React from "react";
import { CustomInput } from "../../../../components";

const BenefitInfoForm = () => {
  return (
    <form>
      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-12">
          <CustomInput label="BVN" />
        </div>
      </section>
      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-6">
          <CustomInput label="Account Holder" />
        </div>
        <div className="col-span-6">
          <CustomInput label="Account Number" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-6">
          <CustomInput label="Bank Name" />
        </div>
        <div className="col-span-6">
          <CustomInput label="Branch" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-6">
          <CustomInput label="Routing Number" />
        </div>
      </section>
    </form>
  );
};

export default BenefitInfoForm;
