import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { CustomButton, CustomInput, CustomSelect } from "../../../../components";
import { FaPlus, FaFileAlt, FaFilePdf, FaFileWord, FaRegTrashAlt } from "react-icons/fa";
import { LuUploadCloud } from "react-icons/lu";
import { MdClose } from "react-icons/md";

const Certification = () => {
  const [certificateList, setCertificateList] = useState([
    {
      certificationName: "",
      certificationAuthority: "",
      certificationNumber: "",
      issueDate: "",
      expiryDate: "",
      document: null,
      preview: null,
    },
  ]);

  const dummyOptions = [
    { value: "", label: "Select" },
    { value: "option 1", label: "Option 1" },
    { value: "option 2", label: "Option 2" },
  ];

  const addCertificate = () => {
    setCertificateList([
      ...certificateList,
      {
        certificationName: "",
        certificationAuthority: "",
        certificationNumber: "",
        issueDate: "",
        expiryDate: "",
        document: null,
        preview: null,
      },
    ]);
  };

  const deleteCertificate = (index) => {
    const updatedCertificateList = certificateList.filter((_, i) => i !== index);
    setCertificateList(updatedCertificateList);
  };

  const handleInputChange = (index, field, value) => {
    const updatedCertificateList = [...certificateList];
    updatedCertificateList[index][field] = value;
    setCertificateList(updatedCertificateList);
  };

  const handleFileDrop = (index, acceptedFiles) => {
    const file = acceptedFiles[0];
    const updatedCertificateList = [...certificateList];
    updatedCertificateList[index].document = file;

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        updatedCertificateList[index].preview = reader.result;
        setCertificateList(updatedCertificateList);
      };
      reader.readAsDataURL(file);
    } else {
      updatedCertificateList[index].preview = null;
      setCertificateList(updatedCertificateList);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedCertificateList = [...certificateList];
    updatedCertificateList[index].document = null;
    updatedCertificateList[index].preview = null;
    setCertificateList(updatedCertificateList);
  };

  const renderFilePreview = (certificate) => {
    if (!certificate.document) return null;

    const fileType = certificate.document.type;

    if (fileType === "image/png" || fileType === "image/jpeg") {
      return <img src={certificate.preview} alt="Preview" className="h-40 w-40 object-cover mr-4" />;
    }

    if (fileType === "application/pdf") {
      return <FaFilePdf className="text-red-600 text-8xl mr-3" />;
    }

    if (
      fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/msword"
    ) {
      return <FaFileWord className="text-blue-600 text-8xl mr-3" />;
    }

    return <FaFileAlt className="text-primary text-8xl mr-3" />;
  };

  return (
    <form>
      {certificateList.map((certificate, index) => (
        <div key={index} className="mb-20">
          <div className="flex justify-between items-center mb-3">
            <h2 className="text-xl font-semibold">Certificate {index + 1}</h2>
            {certificateList.length > 1 && <FaRegTrashAlt className="mr-2 text-red-500 text-xl cursor-pointer" onClick={() => deleteCertificate(index)} />}
          </div>
          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-12">
              <CustomInput
                label="Certification Name"
                value={certificate.certificationName}
                onChange={(e) => handleInputChange(index, "certificationName", e.target.value)}
              />
            </div>
          </section>

          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-6">
              <CustomSelect
                label="Certification Authority"
                name="selectOption"
                options={dummyOptions}
                value={certificate.certificationAuthority}
                onChange={(e) => handleInputChange(index, "certificationAuthority", e.target.value)}
              />
            </div>
            <div className="col-span-6">
              <CustomInput
                label="Certification Number"
                value={certificate.certificationNumber}
                onChange={(e) => handleInputChange(index, "certificationNumber", e.target.value)}
              />
            </div>
          </section>

          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-6">
              <CustomInput
                type="date"
                label="Issue Date"
                value={certificate.issueDate}
                onChange={(e) => handleInputChange(index, "issueDate", e.target.value)}
              />
            </div>
            <div className="col-span-6">
              <CustomInput
                type="date"
                label="Expiry Date"
                value={certificate.expiryDate}
                onChange={(e) => handleInputChange(index, "expiryDate", e.target.value)}
              />
            </div>
          </section>

          <section className="grid gap-5 grid-cols-12">
            <div className="col-span-12">
              {certificate.document ? (
                <div className="flex items-center justify-between bg-gray-100 p-4 rounded-lg">
                  <div className="flex items-center">
                    {renderFilePreview(certificate)}
                    <p className="text-gray-700 font-semibold">{certificate.document.name}</p>
                  </div>
                  <button type="button" className="text-red-600 hover:text-red-800">
                    <MdClose className="text-xl" onClick={() => handleRemoveFile(index)} />
                  </button>
                </div>
              ) : (
                <DropzoneComponent index={index} onDrop={handleFileDrop} />
              )}
            </div>
          </section>

          <div className="flex justify-center">
            <hr className="pt-5 w-6/12 text-primary" />
          </div>
        </div>
      ))}

      <CustomButton
        type="button"
        className="flex items-center font-semibold text-white bg-primary hover:bg-secondary justify-center mb-8 mt-4"
        onClick={addCertificate}
      >
        <span className="pr-2">
          <FaPlus />
        </span>
        <p>Add Another Certificate</p>
      </CustomButton>
    </form>
  );
};

const DropzoneComponent = ({ index, onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(index, acceptedFiles),
  });

  return (
    <div
      {...getRootProps()}
      className={`border-dashed border-2 p-4 rounded-lg ${isDragActive ? "border-blue-600" : "border-gray-300"} `}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="text-center text-blue-600">Drop the files here...</p>
      ) : (
        <div className="flex justify-center items-center flex-col cursor-pointer">
          <LuUploadCloud className="text-8xl text-primary hover:text-secondary" />
          <p className="text-center font-semibold text-gray-600">
            Drag 'n' drop a file here, or
            <span className="text-primary font-bold hover:underline hover:text-secondary"> Browse</span> a file
          </p>
        </div>
      )}
    </div>
  );
};

export default Certification;
