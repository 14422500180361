import { Space, Tag } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdEdit, MdOutlineMoreVert, MdCheck, MdClose } from "react-icons/md";
import { CustomButton } from "../components";

export const filterUser = [
  {
    name: "role",
    label: "Role",
    type: "select",
    options: [
      { label: "Security Guards", value: "security" },
      { label: "Maintenance Officer", value: "maintenance_officer" },
    ],
  },
  {
    name: "status",
    label: "Status",
    type: "select",
    options: [
      { label: "Available", value: "available" },
      { label: "Unavailable", value: "unavailable" },
    ],
  },
];

export const userColumns = ({ onMore }) => [
  {
    title: "Employee ID",
    dataIndex: "employee_id",
    key: "employee_id",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Designation",
    dataIndex: "designation",
    key: "designation",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    // render: (_, { tags }) => (
    //   <>
    //     {tags.map((tag) => {
    //       let color = tag.length > 5 ? "geekblue" : "green";
    //       if (tag === "loser") {
    //         color = "volcano";
    //       }
    //       return (
    //         <Tag color={color} key={tag}>
    //           {tag.toUpperCase()}
    //         </Tag>
    //       );
    //     })}
    //   </>
    // ),
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <Space>
        {onMore(record)()}
      </Space>
    ),
  },
];
