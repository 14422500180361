import { Outlet } from "react-router-dom";
import Sidebar from "./utils/sidebar";
import DashboardHeader from "./utils/dashboard-header";

const LayoutStructure = () => {
  return (
    <>
      <div className="h-screen grid grid-cols-6">
        <div className="col-span-1 sidebar">
          <Sidebar />
        </div>
        <main className="py-3 px-5 bg-bgLight col-span-5">
          <DashboardHeader />
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default LayoutStructure;
