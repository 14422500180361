import React from "react";
import { CustomInput } from "../../../../components";

const HealthInsuranceForm = () => {
  return (
    <form>
      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-12">
          <CustomInput label="Providers" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-6">
          <CustomInput label="Policy Number" />
        </div>
        <div className="col-span-6">
          <CustomInput label="Group Number" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-6">
          <CustomInput type="date" label="Effective Date" />
        </div>
      </section>
    </form>
  );
};

export default HealthInsuranceForm;
