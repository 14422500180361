import React from "react";
import { CustomInput } from "../../../../components";

const PensionInfo = () => {
  return (
    <form>
      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-12">
          <CustomInput label="Provider" />
        </div>
      </section>

      <section className="grid gap-5 grid-cols-12">
        <div className="col-span-12">
          <CustomInput label="Account Number" />
        </div>
      </section>
    </form>
  );
};

export default PensionInfo;
